<template>
  <div ref="ModalBoxRef">
    <a-modal
      v-model:visible="visible"
      :onCancel="handleCancel"
      @ok="handleOk"
      :getContainer="() => ModalBoxRef"
      centered
    >
      <p class="title">{{ isEdit ? '编辑' : '新增' }}标签</p>
      <a-divider />
      <template #footer>
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">保存</a-button>
      </template>
      <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="直播标签名称">
          <a-input v-model:value.trim="labelName" placeholder="请输入直播标签名称" allowClear></a-input>
        </a-form-item>
        <a-form-item label="描述">
          <a-textarea v-model:value.trim="remark" placeholder="200字以内"></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, toRefs, onMounted, nextTick } from 'vue';
import api from '@/services';
import { message } from 'ant-design-vue';

export default defineComponent({
  setup(props, context) {
    const loading = ref(false);
    const visible = ref(false);

    const isEdit = ref(false);
    const type = ref(1);
    const ModalBoxRef = ref();

    const labelCol = reactive({ span: 6, offset: 2 });
    const wrapperCol = reactive({ span: 14 });
    const formState = reactive({
      id: '',
      labelName: '',
      remark: ''
    });

    onMounted(() => {});

    const showModal = (flag, rowData, tagType) => {
      visible.value = true;
      nextTick(() => {
        isEdit.value = flag;
        type.value = tagType;

        if (flag) {
          Object.keys(formState).map(key => {
            formState[key] = rowData[key];
          });
        }
      });
    };

    const handleOk = async () => {
      loading.value = true;
      Object.keys(formState).map(key => {
        if(key == 'id') {}
        else if(!formState[key]) {
          console.log(key)
          loading.value = false
        }
      });
      if(!loading.value) {
        message.error('请输入完整')
        return false 
      }
      const functionName = isEdit.value ? 'updateTag' : 'addTag';

      let params = {
        ...formState,
        type: type.value
      };

      const { success } = await api[functionName](params);
      if (success) {
        message.success(`${isEdit.value ? '编辑' : '新增'}成功！`);
        context.emit('addOrUpdateSuccess');
      }
      loading.value = false;
      visible.value = false;
    };

    const handleCancel = () => {
      visible.value = false;
      clearForm();
    };

    const clearForm = () => {
      Object.keys(formState).map(key => {
        formState[key] = '';
      });
    };

    return {
      type,
      isEdit,
      labelCol,
      wrapperCol,
      ...toRefs(formState),
      loading,
      visible,
      showModal,
      handleOk,
      handleCancel,
      ModalBoxRef,
      clearForm
    };
  }
});
</script>

<style lang="less" scoped>
:deep(.ant-modal-body) {
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .ant-form {
    .ant-form-item {
      .ant-upload-drag-icon {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        :deep(.anticon-plus) {
          color: #d4d4d4;
          font-size: 24px;
        }
      }
      .cover-img {
        height: 148px;
        border-radius: 10px;
      }
    }
  }
}
</style>
